import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index.js'
import common from '@/base/common'
const routes = [
    {
        path:'/login',
        name: 'login',
        component: () => import('@/views/login/index.vue')
    },
    {
        path:'/question/paper/:id',
        name: 'questionPaper',
        component: () => import('@/views/mobile/paper.vue')
    },
    {
        path:'/question/preview/:id',
        name: 'questionPreview',
        component: () => import('@/views/mobile/preview.vue')
    },
    {
        path:'/answer/:id/:uid',
        name: 'answerPaper',
        component: () => import('@/views/mobile/paper.vue')
    },
    {
        path:'/dataFilter/:id',
        name: 'dataFilter',
        component: () => import('@/views/main/notice/analysis/questionDataScreen.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes, 
})

// 退出登录清空router
export const resetRouter = () => {
	let routers = router.getRoutes()
	routers.map(item => {
		if (!['login', 'questionPaper', 'questionPreview'].includes(item.name)) {
			router.removeRoute(item.name)
		}
	})
}

let already = null
router.beforeEach((to, from, next) => {
    let token = store.getters.token
    if(common.whiteList.indexOf(to.name) > -1){
        if(to.name == 'login') already = null
        next()
        return
    }
    if(token){
        if(!already){
            store.dispatch('getMenuData').then(res=>{
                for(var i = 0;i< res.length;i++){
                    if (!router.hasRoute(res[i].name)) {
                        router.addRoute(res[i])
                    }
                }
                already = 'ready'
                next({...to})
            })
        }else next()
        
    }else{
        next({name:'login'})
    }
    
})

export default router