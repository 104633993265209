// import {login,loginOut} from '@/api/base.js'
// import { testJson } from "../testjson/test2"


let noticeTemp = {
	// jsonDoc: testJson
}


export default{
    state:{
        notice: {},
		dragQuestionNo: -1,
		noticeSet: {},
		processNum: -1,
		answerTest:[],
		sectionIndex: -1,
		mapping: null,
		projectInfo:{}
    },
    mutations:{
        setNotice(state,value){
            state.notice = value  //value noticeTemp
			// state.notice = noticeTemp
        },
		setDragQuestionNo(state,value){
			state.dragQuestionNo = value
		},
		setNoticeSet(state,value){
			state.noticeSet = {...state.noticeSet, ...value}
		},
		setProcessNum(state,value){
			state.processNum = value
		},
		setAnswerTest(state,value){
			state.answerTest = value
		},
		setSectionIndex(state,value){
			state.sectionIndex = value
		},
		setMappingList(state,value){
			state.mapping = value
		},
		SetProjectInfo(state,value){
			state.projectInfo = value
		}
    },
    actions:{
        setNoticeData(context,params){
			// console.log(params,'111')
            context.commit('setNotice', {
				...params,
			})
        },
		setNoticeSetData(context,params){
			context.commit('setNoticeSet', params)
		},
		/*
			1.修改根节点上除了question的属性 
			2.修改整个question
			3.根据index修改question 某个属性
		*/
		editQuestionRoot(context,{root,value}){
			root = {...root, ...value}
			// console.log(root,'root')
			context.commit('setNotice',root)
		},
		editQuestionIndex(context,{root,index,value}){
			root.jsonDoc.questions[index] = {...root.jsonDoc.questions[index], ...value}
			// console.log(JSON.parse(JSON.stringify(root.jsonDoc.questions[index])), 'root.jsonDoc.questions[index]')
			context.commit('setNotice',root)
		},
		setDragQuestion(context, params){ //拖拽题目
			// 修改setDragQuestionNo
			context.commit('setDragQuestionNo', params)
		},
		setSection(context, params){ //定位题目
			// 修改定位到哪道题
			context.commit('setSectionIndex', params)
		},
		setMapping(context, params){ //映射关系
			// 修改映射关系
			// console.log(params,'params')
			context.commit('setMappingList', params)
		}
    }
}