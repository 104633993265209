import axios from '@/axios/index.js'
import common from '@/base/common.js'

const baseUrl = common.baseUrl


/**
 * 
 * 角色管理
 * 
 */

//列表
export const queryRolePagedList = (params) => {
	return axios({
	  url: `${baseUrl}/base/role/queryRolePagedList`,
	  method: 'get',
	  params
	})
}

// 获取角色权限
export const queryTopmenuList = (params) => {
	return axios({
		url: `${baseUrl}/base/top/queryTopmenuList`,
		method: 'get',
		params
	})
}

// 新增角色
export const addRole = (params) => {
	return axios({
		url: `${baseUrl}/base/role/addRole`,
		method: 'post',
		data: params
	})
}

// 修改角色
export const updateRole = (params) => {
	return axios({
		url: `${baseUrl}/base/role/modifyRole`,
		method: 'post',
		data:params
	})
}

// 根据角色名称查询是否存在
export const checkName = (params) => {
	return axios({
		url: `${baseUrl}/base/role/checkExists`,
		method: 'get',
		params
	})
}

// 删除角色
export const delRole = (params) => {
	return axios({
		url: `${baseUrl}/base/role/removeRole/${params}`,
		method: 'post',
	})
}


// 授权功能菜单列表
export const queryAuthorizableMenuList = (params) => {
	return axios({
		url: `${baseUrl}/base/menu/queryAuthorizableMenuList`,
		method: 'get',
		params
	})
}

// 当前角色已选menu列表
export const queryAuthorizedMenuList = (params) => {
	return axios({
		url: `${baseUrl}/base/menu/queryAuthorizedMenuList`,
		method: 'get',
		params
	})
}

// 功能权限菜单分配
export const assignMenuRole = (params) => {
	return axios({
		url: `${baseUrl}/base/menu/assignMenuRole`,
		method: 'post',
		data: params
	})
}



// 分配人员未加入成员列表
export const queryUnAssignedUserPagedList = (params) => {
	return axios({
		url: `${baseUrl}/base/role/queryUnAssignedUserPagedList`,
		method: 'get',
		params
	})
}
// 分配人员已加入成员列表
export const queryAssignedUserPagedList = (params) => {
	return axios({
		url: `${baseUrl}/base/role/queryAssignedUserPagedList`,
		method: 'get',
		params
	})
}


// 分配人员
export const assignUserBatch = (params) => {
	return axios({
		url: `${baseUrl}/base/role/assignUserBatch`,
		method: 'post',
		data: params
	})
}


export const unassignUserBatch = (params) => {
	return axios({
		url: `${baseUrl}/base/role/unassignUserBatch`,
		method: 'post',
		data: params
	})
}

// 根据角色获取顶部菜单权限列表
export const queryRoleTopmenuList = (roleId) => {
	return axios({
		url: `${baseUrl}/base/role/queryRoleTopmenuList/${roleId}`,
		method: 'get',
	})
}



export const getRoleList = (params) => {
	return axios({
		url: `${baseUrl}/base/role/roleList`,
		method: 'get',
		params
	})
}