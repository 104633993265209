const notice = [
    {
        name:'question',
        path:'/question',
        component:()=>import('@/views/main/layout.vue'),
        meta:{
            title:'问卷管理'
        },
        children:[
            {
                name:'questionIndex',
                path:'/question/list',
                component: ()=>import('@/views/main/notice/list.vue'),
                meta:{
                    title:'问卷列表'
                }
            },
            {
                name:'questionScene',
                path:'/question/scene',
                component: ()=>import('@/views/main/notice/questionLayout.vue'),
                meta:{
                    title:'查看项目'
                },
                children:[
                    {
                        name:'questionPublish',
                        path:'/question/scene/publish/:id',
                        component: ()=>import('@/views/main/notice/questionPublish.vue'),
                        meta:{
                            title:'查看项目'
                        }
                    },
                    {
                        name:'questionEdit',
                        path:'/question/scene/edit/:id',
                        component: ()=>import('@/views/main/notice/index.vue'),
                        meta:{
                            title:'问卷编辑'
                        }
                    },
                    {
                        name:'questionRelease',
                        path:'/question/scene/release/:id',
                        component: ()=>import('@/views/main/notice/questionRelease.vue'),
                        meta:{
                            title:'问卷投放'
                        }
                    },
                    {
                        name:'questionExecute',
                        path:'/question/scene/execute/:id',
                        component: ()=>import('@/views/main/notice/questionExecute.vue'),
                        meta:{
                            title:'问卷执行情况'
                        }
                    },
                    {
                        name:'questionAnalysis',
                        path:'/question/scene/analysis/:id',
                        component: ()=>import('@/views/main/notice/questionAnalysis.vue'),
                        meta:{
                            title:'问卷分析'
                        }
                    },
                ]
            },
            

        ]
    }
]

export default notice