import {queryUserMenuRouter,queryCurTopmenuList,getOrgBaseInfo} from '@/api/base.js'
import { queryRoleTopmenuList } from '@/api/system/role.js'
import Menu from '@/router/system.js'
import TopMenu from '@/router/topMenu.js'
import _ from 'lodash'
import Question from '@/router/question.js'

const filterAsyncRouter = (asyncRouterMap, menuCus, userInfo) => {
    let menuDatas = {}
    for (let i in menuCus) {
      menuDatas[menuCus[i].name] = {
        ...menuCus[i],
        menuId: menuCus[i].menuId ? Number(menuCus[i].menuId) : i
      };
    }
    const accessedRouters = asyncRouterMap.filter(route => {
      if (route.hidden) {
        route.hasAuthority = false
        return true
      }
      if (route.authority) {
        return true
      }
      if (menuDatas[route.name]) {
        let { menuId, icon, title } = menuDatas[route.name]
        route.menuId = menuId
        
        route.meta = {
          ...route.meta,
          icon: icon,
          title: title
        }
        if (route.children && route.children.length) {
          route.children = filterAsyncRouter(route.children, menuDatas[route.name].children);
        }
        return true
      }
      return false
    })
    accessedRouters.sort(getSortFun('menuId'))
    return accessedRouters
}
const getSortFun = (sortBy) => {
    var sortFun = new Function('a', 'b', 'return Number(a.' + sortBy + ')>' + 'Number(b.' + sortBy + ')?1:-1');
    return sortFun;
}

export default{
    state:{
        menuList:[],
        handleMenu:[],
        menuSliderActive: null,
        menuExpandActive: null,
        menuTopList:[],
        menuActive: null,
        menuFirstInfo: null,
    },
    mutations:{
        setMenuList(state,value){
            state.menuList = value
        },
        setHandleMenu(state,value){
            state.handleMenu = value
        },
        setMenuActive(state,value){
            state.menuActive = value
        },
        setMenuSliderActive(state,value){
            state.menuSliderActive = value
        },
        setMenuExpandActive(state,value){
            state.menuExpandActive = value
        },
        setMenuTopList(state,value){
            state.menuTopList = value
        },
        setMenuFirstInfo(state,value){
            state.menuFirstInfo = value
        },
    },
    actions:{
        async getTopMenu(context){
            return new Promise(async(resolve,reject)=>{
                let userInfo = context.getters.userInfo
                await queryRoleTopmenuList(userInfo.roleId).then(res=>{
                    let menuCus = res.data.value
                    let filterRouters = filterAsyncRouter(TopMenu[0].children, menuCus, context.getters.userInfo)
                    let menuTop = [{
                        ...TopMenu[0],
                        children: filterRouters
                    }]
                    context.commit('setMenuTopList',menuTop)
                    
                    resolve({})
                }).catch(err=>{
                    reject(err)
                })
            })
        },
        async getMenuData(context){
            await context.dispatch('getTopMenu')

            return new Promise(async (resolve,reject)=>{
                let menuTopList = _.cloneDeep(context.getters.menuTopList)  
                let isAuth = menuTopList[0].children.filter(item=>item.name != 'system' && item.name != 'userInfo')
                let question = []
                let redirectInfo = {}
                let menuTop = menuTopList[0].children
                if(menuTop && menuTop.length > 0){
                    menuTop.forEach(item=>{
                        if(item.name == 'notice') question = Question
                    })
                    redirectInfo = {
                        path:'/',
                        name: 'redirectUrl',
                        redirect: menuTop.filter(item=>!item.hidden)[0].path
                    }
                }
                let resolveArr = [
                    redirectInfo,
                    ...menuTopList
                ]

                let userMenuList = await queryUserMenuRouter()
                let systemRoute = []
                let menus = []
                let menusSimple = []
                let menuFirstInfo = null
                let baseMenu = _.cloneDeep(Menu)
                userMenuList.data.value.forEach(item=>{
                    if(item.path == '/') systemRoute = item.children
                })
                if (systemRoute.length) {
                    let subPath = systemRoute[0].children.length ? systemRoute[0].children[0].path : ''
                    let redirectPath = systemRoute[0].path + subPath
                    menuTopList[0].children.map(item => {
                        if (item.name == 'system') {
                            item.redirect = redirectPath
                        }
                    })
                }
                if(systemRoute && systemRoute.length > 0){
                    menus = filterAsyncRouter(baseMenu, systemRoute)
                    menus.forEach((item,index)=>{
                        menusSimple.push({
                            key: item.name + '_' + index + '_menu',
                            label: item.meta.title,
                            path: item.path,
                            name: item.name,
                            title: item.meta.title,
                            children: item.children.map((e,num)=>{
                                return{
                                    key: e.name + '_' + num + '_child_menu',
                                    label: e.meta.title,
                                    title: e.meta.title,
                                    path: item.path + '/' + e.path,
                                    name: e.name,
                                }
                            })
                        })
                        if(item.children && item.children.length > 0){
                            if(!menuFirstInfo){
                                menuFirstInfo = {
                                    ...item.children[0],
                                    url: item.path +'/' +item.children[0].path
                                }
                            }
                        }
                    })
                    context.commit('setMenuFirstInfo', menuFirstInfo)
                    context.commit('setMenuList', systemRoute)
                    context.commit('setHandleMenu', menusSimple)
                }
                resolveArr = [
                    ...resolveArr,
                    ...menus,
                    ...question
                ]
                resolve(resolveArr)
            })
        },
        getOrgInfo(context){
            return new Promise((resolve, reject) => {
                getOrgBaseInfo(context.getters.userInfo.orgId).then(res=>{
                    if(res.data.value){
                        let data = {
                            ...context.getters.userInfo,
                            componyCode: res.data.value.code,
                            logo: res.data.value.logo,
                            componyName: res.data.value.name,
                        }
                        context.commit('setUserInfo',data)
                        let params = {
                            name: res.data.value.name,
                            logo: res.data.value.logo,
                        }
                        console.log(params,'params')
                        localStorage.setItem('logoMc',JSON.stringify(params))
                        resolve({})
                    }
                }).catch(err=>{
                    reject(err)
                })
            })
        }
    }
}