<template>
<a-config-provider :locale="state.locale">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
</a-config-provider>
</template>

<script setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { reactive } from 'vue';
import { message } from 'ant-design-vue';

dayjs.locale('zh-cn');
const state =reactive({
  locale: zhCN
})

message.config({
  top: '5px',
  duration: 2,
  maxCount: 1,
});

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
#app,html,body{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
