<template>
    <a-modal title="图片裁剪" class="systemModalStyle" :visible="modalVisible" width="60%" @cancel="cancel"
        destroyOnClose :maskClosable='false' :keyboard='false' centered>
         <template #footer>
            <a-button class="systemModalStyle-cancel" key="back" @click="cancel">取消</a-button>
            <a-button class="systemModalStyle-submit" key="submit" type="primary" @click="finish">确定</a-button>
        </template>
        <div class="cropper_box">
            <vue-cropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :centerBox="option.centerBox"
                :infoTrue="option.infoTrue"
                :fixedBox="option.fixedBox"
            ></vue-cropper>
        </div>
    </a-modal>
</template>

<script setup>
import { VueCropper }  from 'vue-cropper' 
import { ref, watch,computed, reactive } from 'vue'
import 'vue-cropper/dist/index.css';
const props = defineProps({
    visible: {
        default: false
    },
    imgPath: {
        default: null
    },
    width: {
        default: null
    },
    height: {
        default: null
    },
    fixed: {
        default: true
    },
    fixedNumber: {
        default: ()=>[1, 1]
    }
})
const cropper = ref(null)
const emit = defineEmits(['saveImg','closeCropper','update:visible'])
const option = ref({
    img: '', // 裁剪图片的地址
    info: true, // 裁剪框的大小信息
    outputSize: 0.8, // 裁剪生成图片的质量
    outputType: 'jpg', // 裁剪生成图片的格式
    canScale: false, // 图片是否允许滚轮缩放
    autoCrop: true, // 是否默认生成截图框
    autoCropWidth: 192, // 默认生成截图框宽度
    autoCropHeight: 108, // 默认生成截图框高度
    fixedBox: false, // 固定截图框大小 不允许改变
    fixed: true, // 是否开启截图框宽高固定比例
    fixedNumber: [1, 1], // 截图框的宽高比例
    full: true, // 是否输出原图比例的截图
    canMoveBox: true, // 截图框能否拖动
    original: false, // 上传图片按照原始比例渲染
    centerBox: false, // 截图框是否被限制在图片里面
    infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
})
const finish = ()=>{
    cropper.value.getCropBlob((data) => {
        emit('saveImg', data)
    })
}

const modalVisible = computed({
    get(){
        return props.visible
    },
    set(value){
        emit('update:visible',value)
    }
})
const cancel = ()=>{
    modalVisible.value = false
}

watch(
    ()=>props.visible,
    ()=>{
        option.value = {
            ...option.value,
            img: props.imgPath,
            autoCropWidth: props.width,
            autoCropHeight: props.height,
            // fixed: props.fixed,
            fixedNumber: [props.width,props.height]
        }
    }
)
</script>

<style lang="less" scoped>
.cropper_box {
    width: 100%;
    height: 450px;
}
:deep(.cropper-view-box){
    outline: 1px solid #3662EC;
    outline-color: rgba(54,98,236,50%);
}
</style>