<template>
    <div class="frameHeader">
        <div class="frame-title" v-if="questionInfo">{{questionInfo.name}}</div>
        <div class="frame-nav" v-if="props.type != 'create'">
            <a-breadcrumb :routes="curRoute">
                <template #itemRender="{ route }">
                    <span class="color-active active bread-item" v-if="routeParams && (routeParams.path.indexOf(route.path) > -1) ">
                    {{ route.name }}
                    </span>
                    <router-link v-else :to="`${basePath}/${route.path}/${id}`">
                    {{ route.name }}
                    </router-link>
                </template>
            </a-breadcrumb>
        </div>
        <div class="frame-edit-btn">
             <slot name="btns"></slot>
        </div>
    </div>
</template>
    
<script setup>
import { ref, watch, computed } from "vue";
import {useRoute} from 'vue-router'
import _ from 'lodash'
import {useStore} from 'vuex'
const store = useStore()
const routeParams  = useRoute()
const id = ref('')
const props = defineProps({
    type:{
        type: String,
        default: ''
    }
});
const questionInfo = ref(store.getters.noticeInfo)

watch(
    ()=>store.getters.noticeInfo,
    (val,oldVal)=>{
        questionInfo.value = val
    }
)

const routes = ref([
    {
        path: 'edit',
        name: '问卷制作',
        statusRole: [0, 1, 2, 3]
    },
    {
        path: 'release',
        name: '问卷投放',
        statusRole: [1, 2, 3]
    },
    {
        path: 'execute',
        name: '问卷执行情况',
        statusRole: [1, 2, 3]
    },
    {
        path: 'analysis',
        name: '问卷分析',
        statusRole: [1, 2, 3]
    },
])

const curRoute = computed(() => {
    if (questionInfo.value) {
        return routes.value.filter(e => e.statusRole.indexOf(questionInfo.value.status) > -1)
    } else {
        return routes.value
    }
})


const basePath = ref('/question/scene')

const init = ()=>{
    if(!_.isEmpty(routeParams.params)) id.value = routeParams.params.id
}
init()
</script>
    
<style lang="less" scoped>
@import url('@/assets/css/theme.less');
.frameHeader {
    width: 100%;
    height: 48px;
    padding: 0 40px;
    background: @color-white;
    border-bottom: 1px solid @border-color;
    overflow: hidden;
    .frame-title{
        
        font-size: 20px;
        font-weight: 600;
        
    }
    .frame-title,
    .frame-nav{
        float: left;
        line-height: 48px;
        height: 48px;
    }
    .frame-edit-btn{
        float: right;
        padding: 8px 0;
    }
     .frame-nav{
        padding: 0 20px;
        :deep(.ant-breadcrumb){
            line-height: 48px;
            
        }
        :deep(.ant-breadcrumb a){
            color: @text-base-color;
            display: inline-block;
        }
        .bread-item{
            display: inline-block;
        }
        .active{
            font-weight: 600;
            position: relative;
            &::before{
                position: absolute;
                content: "";
                width: 20px;
                height: 3px;
                border-radius: 4px;
                background: @color-active;
                left: 50%;
                bottom: 1px;
                transform: translateX(-50%);
            }
        }
    }
}  
</style>