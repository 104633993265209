const menuList = [
    {
        path:'/index',
        name:'layoutIndex',
        component:()=>import('@/views/main/layout.vue'),
        children:[
            // {
            //     path:'/index/index',
            //     name:'index',
            //     component:()=>import('@/views/main/index/index.vue'),
            //     meta:{
            //         title:'首页'
            //     }
            // },
            {
                path:'/index/question',
                name:'notice',
                redirect:'/question/list',
                meta:{
                    title:'问卷管理'
                },
            },
            {
                path:'/index/system',
                name:'system',
                meta:{
                    title:'控制台'
                }
            },
            {
                path:'/index/userInfo',
                name:'userInfo',
                hidden:true,
                meta:{
                    title:'个人信息'
                },
                component:()=>import('@/views/main/index/userInfo.vue'),
            }
        ]
    }
]
export default menuList