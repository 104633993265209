const system = [
    {
        name:'System',
        path:'/system',
        component: ()=>import('@/views/system/layout.vue'),
        meta:{
            title:'系统管理'
        },
        children:[
            {
                name:'Role',
                path:'role',
                component: ()=>import('@/views/system/role/index.vue'),
                meta:{
                    title:'角色管理'
                }
            },
            {
                name:'User',
                path:'user',
                component: ()=>import('@/views/system/user/index.vue'),
                meta:{
                    title:'用户管理'
                }
            },
            {
                name:'Dept',
                path:'dept',
                component: ()=>import('@/views/system/dept/index.vue'),
                meta:{
                    title:'组织机构'
                }
            },
        ]
    },
    {
        name:'Auth',
        path:'/auth',
        component: ()=>import('@/views/system/layout.vue'),
        meta:{
            title:'权限管理'
        },
        children:[
            {
                name:'roleAuth',
                path:'roleAuth',
                component: ()=>import('@/views/system/auth/roleAuth.vue'),
                meta:{
                    title:'角色权限管理'
                }
            },
            {
                name:'deptAuth',
                path:'deptAuth',
                component: ()=>import('@/views/system/auth/deptAuth.vue'),
                meta:{
                    title:'企业权限管理'
                }
            },
        ]
    },
]

export default system