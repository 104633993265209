import axios from 'axios'
import store from '@/store'
import common from '@/base/common.js'
import router from '@/router/index.js'
import {message} from 'ant-design-vue'
import { resetRouter } from '@/router/index.js'

const Axios = axios.create({
    baseURL: '/api/',
    timeout: 180000,
});

const error = (error)=>{
    if (error.response) {
		if (error.response.status === 500) {
			console.log(error.response.data,'1')
			message.error(error.response.data.resultMessage);
		}
		if(error.response.status === 401){
			console.log(error.response.status)
			store.commit('setToken', null)
			store.commit('setUserInfo', null)
			store.commit('setMenuList', null)
			store.commit('setHandleMenu', null)
			store.commit('setMenuFirstInfo', null)
			store.commit('setMenuActive', null),
			store.commit('setMenuTopList', null)
            store.commit('setMenuSliderActive', null)
			store.commit('setMenuExpandActive', null)
			sessionStorage.clear()
			resetRouter()
			router.push('/login')
		}
	}
    return Promise.reject(error);
}

// 添加请求拦截器
Axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
	let token = store.getters.token
	if (token) {
		config.headers['Authentication'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
	} else {
		if (!common.whiteList.indexOf(router.currentRoute.path) === -1) {
			router.replace('/login')
		}
	}
    return config;
  }, error);

// 添加响应拦截器
Axios.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response;
  }, error);


export default Axios