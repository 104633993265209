import axios from '@/axios/index.js'
import common from '@/base/common.js'

const baseUrl = common.baseUrl


// 组织机构列表
export const queryOrgList = (params) => {
	return axios({
	  url: `${baseUrl}/base/org/queryOrgList`,
	  method: 'get',
	  params
	})
}


// 组织机构列表
export const queryOrgListByParentId = (params) => {
	return axios({
	  url: `${baseUrl}/base/org/queryOrgListByParentId`,
	  method: 'get',
	  params
	})
}

//上传营业执照
export const uploadLicense = (params) => {
	return axios({
		url: `${baseUrl}/base/org/uploadLicense`,
		method: 'post',
		data: params
	})
}



export const uploadLogo = (params) => {
	return axios({
		url: `${baseUrl}/base/org/uploadLogo`,
		method: 'post',
		data: params
	})
}

//添加组织
export const addOrg = (params) => {
	return axios({
		url: `${baseUrl}/base/org/addOrg`,
		method: 'post',
		data: params
	})
}


//编辑组织
export const modifyOrg = (params) => {
	return axios({
		url: `${baseUrl}/base/org/modifyOrg`,
		method: 'post',
		data: params
	})
}

export const removeOrg = (params) => {
	return axios({
		url: `${baseUrl}/base/org/disableOrg`,
		method: 'post',
		params
	})
}



export const enableOrg = (params) => {
	return axios({
		url: `${baseUrl}/base/org/enableOrg`,
		method: 'post',
		params
	})
}

export const queryOrgDetailOne = (params) => {
	return axios({
		url: `${baseUrl}/base/org/queryOrgDetailOne/${params}`,
		method: 'get',
		
	})
}

/**
 * 
 * 部门
 * 
 */


export const getDeptList = (params) => {
	return axios({
	  url: `${baseUrl}/base/dept/list`,
	  method: 'get',
	  params
	})
}


export const addDept = (params) => {
	return axios({
		url: `${baseUrl}/base/dept/add`,
		method: 'post',
		data: params
	})
}

export const modifyDept = (params) => {
	return axios({
		url: `${baseUrl}/base/dept/modify`,
		method: 'post',
		data: params
	})
}

export const removeDept = (params) => {
	return axios({
		url: `${baseUrl}/base/dept/remove/${params}`,
		method: 'post',
	})
}


export const getDeptPage = (params) => {
	return axios({
	  url: `${baseUrl}/base/dept/list`,
	  method: 'get',
	  params
	})
}


export const getDeptUserList = (params) => {
	return axios({
	  url: `${baseUrl}/base/dept/userList`,
	  method: 'get',
	  params
	})
}




