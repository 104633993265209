import axios from '@/axios/index.js'
import common from '@/base/common.js'

const baseUrl = common.baseUrl

/**
 * 
 * 问卷项目
 * 
 */


// 创建项目
export const addProject = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/addProject`,
	  method: 'post',
	  data: params
	})
}


export const editName = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/editName`,
	  method: 'post',
	  data: params
	})
}

// 查询项目列表
export const queryProjectPagedList = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/queryProjectPagedList`,
	  method: 'get',
	  params
	})
}

// 删除项目
export const delProject = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/delProject/${params}`,
		method: 'post',
	})
}

// 开启项目
export const openProject = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/openProject/${params}`,
		method: 'post',
	})
}

// 停用项目
export const stopProject = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/stopProject/${params}`,
		method: 'post',
	})
}

//复制项目
export const copyProject = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/copyProject/${params}`,
		method: 'post',
	})
}


export const copyFullProject = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/copyFullProject/${params}`,
		method: 'post',
	})
}

export const viewPublishProjectDetail = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/viewPublish/${params}`,
		method: 'post',
	})
}

export const viewProjectDetail = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/view/${params}`,
		method: 'post',
	})
}


/**
 * 
 * 
 * 问卷
 * 
 */

//保存问卷
export const saveQuestions = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/editQuestions`,
	  method: 'post',
	  data: params
	})
}

// 发布问卷
export const publishQuestion = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/publish/${params}`,
		method: 'get',
	})
}

// 查看项目配置
export const viewConfig = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/viewConfig/${params}`,
		method: 'post',
	})
}

// 更新保存项目配置
export const saveConfig = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/editConfig`,
		method: 'post',
		data: params
	})
}

// 设置回收上限
export const setRecycle = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/setRecycle`,
	  method: 'post',
	  data: params
	})
}
// 设置回收结束时间
export const setEndTime = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/setEndTime`,
	  method: 'post',
	  data: params
	})
}

// 获取统计数据
export const getStatistics = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/questionAnalyzeData`,
	  method: 'get',
	  params
	})
}

// 获取所有QID by PID
export const getAllQuestion = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/allQuestions`,
	  method: 'get',
	  params
	})
}

// 获取问卷Questions包含 合并题
export const getAllQuestionByPid = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/questions/${params}`,
	  method: 'get',
	})
}

// 获取问卷执行概况
export const getExecuteOverview = (params) => {
	return axios({
	  url: `${baseUrl}/overview/get`,
	  method: 'get',
	  params
	})
}

// 导出分析图表
export const analyzePage = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/analyzePage`,
	  method: 'get',
	  params,
	  timeout: 0
	})
}

// 获取问卷分析数据导出 - word
export const questionAnalyzeWord = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/questionAnalyzeWord`,
	  method: 'get',
	  params,
	  timeout: 0
	})
}

// 获取问卷分析数据导出 - spss
export const downloadAnswerDetail = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/downloadAnswerDetail`,
	  method: 'get',
	  params,
	  responseType: 'blob'
	})
}


export const downloadAnswerDetailCsv = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/downloadAnswerDetailCsv`,
	  method: 'get',
	  params,
	  responseType: 'blob'
	})
}

// 导出问卷题目
export const exportQuestions = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/downloadQuestion`,
	  method: 'get',
	  params,
	  responseType: 'blob'
	})
}


export const downloadAnalyzeData = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/downloadAnalyzeData`,
	  method: 'get',
	  params,
	  responseType: 'blob'
	})
}

// 清空问卷答题数据
export const surveyCleanData = (params) => {
	return axios({
	  url: `${baseUrl}/survey/project/cleanData/${params}`,
	  method: 'get',
	})
}

// 下载题目具体答案列表(Excel)
export const downloadQuestionAnswers = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/downloadQuestionAnswers`,
	  method: 'get',
	  params,
	  responseType: 'blob'
	})
}

export const getProjectDetail = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/get/${params}`,
		method: 'post',
	})
}


export const downloadAnswerChangeModel = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/downloadAnswerChangeModel`,
	  method: 'get',
	  params,
	  responseType: 'blob'
	})
}


export const coverAnswer = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/coverAnswer`,
	  method: 'post',
	  data: params
	})
}

// 设置数据筛选开关
export const setCondition = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/setCondition`,
	  method: 'post',
	  data: params
	})
}

// 获取有效筛选条件
export const getValidConditions = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/getValidConditions`,
	  method: 'get',
	  params
	})
}

// 获取数据筛选条件状态
export const getConditionState = (params) => {
	return axios({
	  url: `${baseUrl}/survey/analyze/getConditionState`,
	  method: 'get',
	  params
	})
}



export const uploadFile = (params) => {
	return axios({
	  url: `${baseUrl}/survey/answer/upload`,
	  method: 'post',
	  data: params
	})
}

// 问卷分析下载zip
export const downloadZip = (params) => {
	return axios({
		url: `${baseUrl}/survey/analyze/downloadZip`,
		method: 'get',
		params,
	  	responseType: 'blob'
	})
}

// 获取自定义参数列表
export const getCustomParams = (params, projectId) => {
	return axios({
		url: `${baseUrl}/survey/project/queryBatchCustomerList//${projectId}`,
		method: 'get',
		params
	})
}

// 添加单个自定义配置URL
export const addSingleCustomerURL = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/addCustomerURL`,
		method: 'post',
		data: params
	})
}

// 删除自定义配置URL
export const delCustomerURL = (params) => {
	return axios({
		url: `${baseUrl}/survey/project/batchDelCustomerURL`,
		method: 'post',
		data: params
	})
}

// 添加批量自定义配置URL
export const addBatchCustomerURL = (params, projectId) => {
	return axios({
		url: `${baseUrl}/survey/project/batchCustomerURL/${projectId}`,
		method: 'post',
		data: params
	})
}


// 查询用户答题列表
export const answerUserList = (params) => {
	return axios({
		url: `${baseUrl}/overview/answerUserPage`,
		method: 'get',
		params
	})
}


export const batchGenerate = (params) => {
	return axios({
		url: `${baseUrl}/overview/batchGenerate`,
		method: 'post',
		params
	})
}


export const batchDownload = (params) => {
	return axios({
		url: `${baseUrl}/overview/batchDownload`,
		method: 'get',
		params,
		responseType: 'blob'
	})
}

// 查询当前用户企业权限列表
export const authoritiesForUser = (params) => {
	return axios({
		url: `${baseUrl}/authority/authoritiesForUser`,
		method: 'get',
		params
	})
}

// 保存组织机构权限
export const saveAuthoritiesForOrg = (params) => {
	return axios({
		url: `${baseUrl}/authority/saveAuthoritiesForOrg`,
		method: 'post',
		data:params
	})
}

// 保存角色权限
export const saveAuthoritiesForRole = (params) => {
	return axios({
		url: `${baseUrl}/authority/saveAuthoritiesForRole`,
		method: 'post',
		data:params
	})
}


// 查询组织机构权限
export const authoritiesForOrg = (params) => {
	return axios({
		url: `${baseUrl}/authority/authoritiesForOrg`,
		method: 'get',
		params
	})
}

// 查询角色权限
export const authoritiesForRole = (params) => {
	return axios({
		url: `${baseUrl}/authority/authoritiesForRole`,
		method: 'get',
		params
	})
}
