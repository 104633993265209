import axios from '@/axios/index.js'
import common from '@/base/common.js'

const baseUrl = common.baseUrl

/**
 * 
 * 登录
 * 
 */

export const login = (params) => {
	return axios({
		url: `${baseUrl}/login`,
		method: 'post',
		params,
	})
}

export const loginOut = (params) => {
	return axios({
	  url: `${baseUrl}/logout/${params}`,
	  method: 'get'
	})
  }

//获取系统菜单
export const queryUserMenuRouter = (params) => {
	return axios({
	  url: `${baseUrl}/base/menu/queryUserMenuRouter`,
	  method: 'get',
	  params
	})
}

// 获取顶部菜单权限
export const queryCurTopmenuList = (params) => {
	return axios({
	  url: `${baseUrl}/base/top/queryCurTopmenuList`,
	  method: 'get',
	  params
	})
}

export const getOrgBaseInfo = (params) => {
	return axios({
	  url: `${baseUrl}/base/org/one/${params}`,
	  method: 'get',
	  
	})
}


export const getUserInfo = (params) => {
	return axios({
	  url: `${baseUrl}/base/user/one`,
	  method: 'get',
	  params
	})
}



export const uploadAvatar = (params) => {
	return axios({
		url: `${baseUrl}/base/user/uploadAvatar`,
		method: 'post',
		data: params
	})
}


export const uploadTitle = (params,id) => {
	return axios({
		url: `${baseUrl}/survey/project/upload/${id}`,
		method: 'post',
		data: params
	})
}

// 保存前端缓存
export const savePreCache = (params,id) => {
	return axios({
		url: `${baseUrl}/survey/answer/savePreCache`,
		method: 'post',
		data: params
	})
}