import {login,loginOut,getUserInfo} from '@/api/base.js'
import { resetRouter } from '@/router/index.js'
import {authoritiesForRole} from '@/api/question'
export default{
    state:{
        token: null,
        userInfo: null,
		programData:{},
        roleAuth:[]
    },
    mutations:{
        setToken(state,value){
            state.token = value
        },
        setUserInfo(state,value){
            state.userInfo = value
        },
        setProgramData(state,value){
			state.programData = value
		},
        setRoleAuth(state,value){
			state.roleAuth = value
		},
    },
    actions:{
        userLogin(context,params){
            return new Promise((resolve, reject) => {
                login(params).then(res=>{
                    if(res.data.resultCode == 200){
                        res.data.value.user = {
                            ...res.data.value.user,
                            isAdmin: res.data.value.user.roleId == '1',
                            isSecondAdmin: res.data.value.user.roleId == '3',
                        }
                        let parent = res.data.value.authList.filter(item=>!item?.supCode)
                        let list = []
                        list = parent.map(item=>{
                            let children = res.data.value.authList.filter(e=>e.supCode == item.authCode)
                            return{
                                ...item,
                                children
                            }
                        })
                        context.commit('setRoleAuth',list)
                        context.commit('setToken',res.data.value.token)
                        context.commit('setUserInfo',res.data.value.user)
                        if(!res.data.value.user.roleId){
                            resolve({
                                message: '当前账户未分配角色，请联系管理员！'
                            })
                        }else{
                            resolve({
                                code: 200,
                                value: res.data.value
                            })
                        }
                       
                    }
                }).catch(err=>{
                    reject(err)
                })
            })
        },
        userExist(context,params){
            return new Promise((resolve, reject) => {
                loginOut(params).then(res => {
                    context.commit('setToken', null)
                    context.commit('setUserInfo', null)
                    context.commit('setMenuList', null)
                    context.commit('setHandleMenu', null)
                    context.commit('setMenuFirstInfo', null)
                    context.commit('setMenuActive', null)
                    context.commit('setMenuTopList', null)
                    context.commit('setMenuSliderActive', null)
                    context.commit('setMenuExpandActive', null)
                    context.commit('setNotice', null)
                    context.commit('SET_DICT', null)
                    sessionStorage.clear()
                    resetRouter()
                    resolve()
                  }).catch(err => {
                    reject(err)
                  })
            })
        },
        getUserBaseInfo(context){
            return new Promise((resolve, reject) => {
                getUserInfo({id: context.getters.userInfo.id}).then(res=>{
                    if(res.data.resultCode == 200){
                        context.commit('setUserInfo',{
                            ...context.getters.userInfo,
                            avatar: res.data.value.user.avatar,
                            mobile: res.data.value.user.mobile,
                            deptList: res.data.value.deptList || [],
                            isFreezed: res.data.value.user.isFreezed,
                        })
                        
                    }
                    resolve({})
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getRoleAuthInfo(context){
            return new Promise((resolve, reject) => {
                authoritiesForRole({roleId: context.getters.userInfo.roleId}).then(res=>{
                    if(res.data.resultCode == 200){
                        let parent = res.data.value.filter(item=>!item?.supCode)
                        let list = []
                        list = parent.map(item=>{
                            let children = res.data.value.filter(e=>e.supCode == item.authCode)
                            return{
                                ...item,
                                children
                            }
                        })
                        context.commit('setRoleAuth',list)
                    }
                    resolve({})
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}