<template>
    <div class="account">
        <div class="userImg">
            <a-dropdown>
                <div class="info_cell">
                    <img class="imgBox" v-if="state.headImg" :src="state.headImg" @error="error" />
                    <a-avatar :size="45" class="icon" v-else>
                        <template #icon><UserOutlined /></template>
                    </a-avatar>
                    <span class="name">{{ state.userName}}</span>
                </div>
                <template #overlay>
                    <a-menu >
                        <a-menu-item key="1" @click="toUser">个人中心</a-menu-item>
                        <a-menu-item key="2" @click="exit">退出</a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
        </div>
    </div>
</template>
<script setup>
import { reactive, watch } from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import { UserOutlined } from '@ant-design/icons-vue';
import common from '@/base/common.js'
const store = useStore()
const router = useRouter()
const state = reactive({
    headImg: null,
    userName: ''
})
const exit = ()=>{
    store.dispatch('userExist',store.getters.userInfo.id).then(res=>{
        router.push('/login')
    })
}
const toUser = ()=>{
    router.push('/index/userInfo')
}

const error = ()=>{
    state.headImg = null
}

watch(
    ()=> store.getters.userInfo?.avatar,
    (newVal, oldVal) => {
        init()
    }
)

const init = ()=>{
    let time = new Date().getTime()
    if(store.getters.userInfo?.avatar) state.headImg = common.imgBaseUrl + store.getters.userInfo.avatar +'?v=' +time
    if(store.getters.userInfo?.name) state.userName = store.getters.userInfo.name
}
init()
</script>
<style lang="less" scoped>
@import url('@/assets/css/theme.less');
.account{
    display: flex;
    align-items: center;
    .info_cell {
        display: flex;
        align-items: center;
        .name {
            margin-left: 10px;
            font-size: 16px;
            line-height: 18px;
        }
    }
    .userImg{
        .icon{
            background-color: @color-active;
            display: block;
            cursor: pointer;
        }
        .imgBox{
            width: 45px;
            height: 45px;
            padding: 3px;
            border-radius: 50%;
            border: 1px solid @border-color;
        }
    }
}
</style>