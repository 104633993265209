import axios from 'axios';

const dict = {
    state: {
        dict: null,
    },

    mutations: {
        SET_DICT: (state, type) => {
            state.dict = type;
        },
    },
    actions:{
        async getDict(context,type){
            return new Promise(resolve => {
                axios.get('/json/dictionary.json?v=' + (new Date().getTime())).then(res => {
                    if(!type) context.commit('SET_DICT', res.data);
                    resolve(res.data);
                }, err => {
                    resolve('err')
                });
            })
        }
    }
}

export default dict