<template>
    <div class="logoBox">
        <a-image class="img" :width="140" :preview="false" :height="45" :src="state.headImg" :fallback="require('@/assets/images/logo.png')"/>
        <!-- <div class="title">
            <label class="value">{{state.componyName}}</label>
            <label class="mc">问卷调研</label>
        </div> -->
    </div>
</template>
<script setup>
import common from '@/base/common.js'
import { reactive, watch } from 'vue'
import {useStore} from 'vuex'
const props = defineProps({
    logoFrom:{
        type: String,
        default: ''
    }
})

const store = useStore()
const state = reactive({
    userInfo: null,
    headImg: null,
    componyName: null
})

watch(
    ()=> store.getters.userInfo,
    (newVal, oldVal) => {
        init()
    }
)

const init = ()=>{
    if(props.logoFrom == 'login'){
        const logoMc = localStorage.getItem('logoMc') ? JSON.parse(localStorage.getItem('logoMc')) : null
        if(logoMc){
            state.componyName = logoMc.name
            if(logoMc.logo) state.headImg = common.imgBaseUrl + logoMc.logo
            else state.headImg = require('@/assets/images/logo.png')
        }else{
            state.componyName = common.notice_name
            state.headImg = require('@/assets/images/logo.png')
        }
    }else{
        state.userInfo = store.getters.userInfo
        if(state.userInfo?.logo) state.headImg = common.imgBaseUrl + state.userInfo.logo
        else state.headImg = require('@/assets/images/logo.png')
        state.componyName = state.userInfo?.componyName || ''
    }
}
init()

</script>
<style lang="less" scoped>
@import url('@/assets/css/theme.less');
.logoBox{
    min-width: 150px;
    transition: all 0.5s;
    .title{
        display: inline-block;
         vertical-align: middle;
        font-weight: 600;
        margin-left: 10px;
        .value{
            font-size: @font-size-superBig;
            white-space: nowrap;
        }
        .value,
        .mc{
            display: block;
            line-height: 22px;
            text-align: center;
        }
        .mc{
            letter-spacing: 6px;
        }
    }
    :deep(.ant-image){
        display: inline-block;
         vertical-align: middle;
         overflow: hidden;
    }
   :deep(.ant-image-img){
        display: block;
    }
}
</style>