<template>
    <a-modal class="systemModalConfirmStyle" :closable="false" :footer="null" width="416px" @cancel="cancel" :visible="modalVisible" :destroyOnClose="true">
        <div class="confirm">
            <div class="confirm-content">
               <div class="confirm-item">
                    <div class="icon">
                        <info-circle-outlined />
                    </div>
                    <div class="text">{{props.description}}</div>
                    <div class="child">{{props.subDes}}</div>
               </div>
            </div>
            <div class="confirm-footer">
                <a-button class="systemModalStyle-cancel" key="back" @click="cancel">取消</a-button>
                <a-button class="systemModalStyle-submit" key="submit" type="primary" @click="save" :loading="loading">确定</a-button>
            </div>
            
        </div>
    </a-modal>
</template>
<script setup>
import { computed, reactive,ref } from "vue"
import {InfoCircleOutlined} from '@ant-design/icons-vue';
const props = defineProps({
    visible: {
        type: Boolean,
        default: false
    },
    description:{
        type: String,
        default:''
    },
    subDes:{
        type: String,
        default:''
    }
})

const emits = defineEmits(['save'])
const modalVisible = ref(false)
const loading = ref(false)

const cancel = ()=>{
    modalVisible.value = false
}

const save= ()=>{
    // state.loading = true
    emits('save')
}


defineExpose({
    modalVisible,
    loading
})
</script>
<style lang="less" scoped>
@import url('@/assets/css/theme.less');
.confirm{
    min-height: 50px;
    .confirm-item{
        overflow: hidden;
        .icon{
            float: left;
            color: @warning-color;
            margin-right: 16px;
            span{
                font-size: 22px;
            }
        }
        .text{
            color: @color-black;
            font-weight: 500;
            font-size: 16px;
            display: block;
            overflow: hidden;
        }
    }
    .confirm-footer{
        float: right;
        margin-top: 24px;
        .systemModalStyle-cancel{
            margin-right: 10px;
        }
    }
    .child{
        padding-left:38px;
    }
}
</style>