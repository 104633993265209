import { createStore } from 'vuex'
import getters from './getter'
import User from './modules/use'
import Menu from './modules/menu'
import Notice from './modules/notice'
import Dict from './modules/dict'
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
    modules:{
        User,
        Menu,
        Notice,
        Dict
    },
    getters,
    plugins:[
        createPersistedState({
            key:'vuex',
            storage: window.sessionStorage,
            paths: ['User', 'Menu', 'Dict']
        })
    ]
})

export default store