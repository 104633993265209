<template>
    <div class="uploadBox">
        <a-upload
            v-model:file-list="state.fileList"
            name="avatar"
            list-type="picture-card"
            class="avatar-uploader"
            action="#"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="handleChange"
            @preview="handlePreview"
        >
            <div class="review" v-if="state.imageUrl" :class="{'avatarView': props.name == 'avatar'}">
                <img class="uploadImg" :src="state.imageUrl" alt="avatar" @error="error"/>
                <!-- <div class="del" @click.stop="delImg('clear')">
                    <span class="icon iconfont iconshanchu"></span>
                </div> -->
                <div v-if="props.name == 'avatar'" class="emptyImg-text">编辑</div>
            </div>
            <div class="emptyImg" v-else :class="{'normalUplaod': props.name != 'avatar'}">
                <div class="emptyInfo" v-if="props.name == 'avatar'">
                    <div class="icon"> 
                        <UserOutlined/>
                    </div>
                    <div class="emptyImg-text">编辑</div>
                </div>
                <template v-else>
                    <LoadingOutlined v-if="state.loading"></LoadingOutlined>
                    <PlusOutlined v-else></PlusOutlined>
                    <div class="ant-upload-text">上传图片</div>
                </template>
                
            </div>
        </a-upload>
        <a-modal class="systemModalStyle" :visible="state.previewVisible" title="预览" :footer="null" @cancel="state.previewVisible = false" :destroyOnClose="true">
            <img alt="example" style="width: 100%" :src="state.previewImage" />
        </a-modal>
        <ImageCropper v-model:visible="imageVisible" :imgPath="cropperImg" :width="getSize('width')" :height="getSize('height')" @saveImg="saveImg"/>
    </div>
    
</template>
<script setup>
import { reactive, ref,toRefs } from "vue";
import { PlusOutlined, LoadingOutlined,UserOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import {uploadLicense,uploadLogo} from '@/api/system/dept.js'
import {uploadAvatar} from '@/api/base.js'
import common from '@/base/common.js'
const props = defineProps({
    name:{
        type: String,
        default: ''
    }
})
const emits = defineEmits(['uploadSuccess'])
const state = reactive({
    fileList: [],
    maxSize:10,
    previewVisible:false,
    previewImage: null,
    imageUrl: null
})
const cropperImg = ref(null)
const imageVisible = ref(false)
const fixedNumber = ref(null)
const beforeUpload = (file)=>{
    //限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isPNG = file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < state.maxSize;
    if (!(isJPG || isJPEG || isPNG)) {
        message.error('只能上传JPG、JPEG、PNG格式的图片~')

    } else if (!isLt2M) {
        message.error('图片超过'+state.maxSize+'M限制，不允许上传~')
    }
    return (isJPG || isJPEG || isPNG) && isLt2M;
}

const handleChange = (info)=>{
    const { file, filename } = info;
    var reads = new FileReader();
    reads.readAsDataURL(file);
    reads.onload = function (r) {
        cropperImg.value = this.result;
        imageVisible.value = true;
    };
   
}

const saveImg = (file)=>{
    let formdata = new FormData()
    formdata.append('file', file); 
    let api = ''
    if(props.name == 'license') api = uploadLicense(formdata)
    else if(props.name == 'logo') api = uploadLogo(formdata)
    else if(props.name == 'avatar') api = uploadAvatar(formdata)
    if(api){
        api.then(res=>{
            state.imageUrl = common.imgBaseUrl + res.data.value
            imageVisible.value = false
            emits('uploadSuccess',res.data.value,props.name)
        }).catch(err=>{

        })
    }
}

// 截图大小
const getSize = (type)=>{
    if (type == "width") {
        if (props.name == "logo") return 140;
        else if (props.name == "license") return 496;
    }
    if (type == "height") {
        if (props.name == "logo") return 45;
        else if (props.name == "license") return 350;
    }
}

const handlePreview =()=>{
      state.previewImage = state.imageUrl;
      state.previewVisible = true;
}   

const delImg = (type)=>{
    state.imageUrl = null
    state.fileList = []
    if(type) emits('uploadSuccess',null,props.name)
}

const error = ()=>{
    state.imageUrl = null
}

defineExpose({
    ...toRefs(state),
    delImg
})
</script>
<style lang="less" scoped>
@import url('@/assets/css/theme.less');
.uploadImg{
    width: 104px;
    height: 104px;
    object-fit: contain;
}
.review{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    .del{
        width: 60px;
        height: 60px;
        background: @color-red;
        position: absolute;
        top: -30px;
        right: -30px;
        transform: rotate(45deg);
        cursor: pointer;
        z-index: 101;
        .icon{
            font-size: @font-size-big;
            color: @color-white;
            transform: rotate(-45deg);
            position: absolute;
            bottom: 1px;
            left: 24px;
        }
    }
    &.avatarView{
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        .emptyImg-text{
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            background: @color-gray;
            color: @color-white;
            line-height: 30px;
        }
    }
}
.emptyImg{
    width: 100%;
    height: 100%;
    .emptyInfo{
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        .icon{
            width: 100%;
            height: 100%;
            background: @color-active;
            color: @color-white;
            border-radius: 50%;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                margin-bottom: 20px;
            }
        }
        .emptyImg-text{
            position: absolute;
            left: 0;
            width: 100%;
            bottom: 0;
            background: @color-gray;
            color: @color-white;
            line-height: 30px;
        }
    }
    &.normalUplaod{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
</style>