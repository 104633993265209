<template>
    <div class="topMenu">
        <div class="topMenuStyle">
            <div class="menuItem" :class="{'active': state.activeIndex == item.name}" v-for="(item,index) in state.menus" :key="item.name + '_' + index + '_top_menu'" @click="changeMenu(item)">
                <span class="title">{{item.meta.title}}</span>
            </div>
        </div>
    </div>
</template>
<script setup>
import {reactive, ref, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter,useRoute} from 'vue-router'
const store = useStore()
const route = useRoute()
const router = useRouter()
const state = reactive({
    menus: [],
    selectedKeys: [],
    openKeys: [],
    activeIndex: store.getters.menuActive || 'index'
})

watch(
    () => route.params,
    () => {
        let index = ''
        if(route.name == 'index') index = 'index'
        else if(route.path.indexOf('question') > -1) index = 'notice'
        else if(route.path.indexOf('system') > -1) index = 'system'
        else if(route.path.indexOf('userInfo') > -1) index = ''
        state.activeIndex = index
        store.commit('setMenuActive', state.activeIndex)
    },
    { immediate: true, deep: true }
);

state.menus = store.getters.menuTopList[0].children.filter(item=>item.name != 'userInfo')


const menuSelect = ({ item, key, selectedKeys })=>{
    console.log(item, key, selectedKeys)
}

const menuOpen = (openKeys)=>{
    console.log(openKeys)
}

const changeMenu = (item)=>{
    state.activeIndex = item.name
    store.commit('setMenuActive', item.name)
    let url = ''
    if(item.name == 'system'){
        let menu = store.getters.menuFirstInfo
        if(menu) url = menu.url
        store.commit('setMenuSliderActive', null)
    }else if(item.name == 'question'){
        url = '/question/list'
    }       
    else{
        url = item.path
    }
    router.push(url)
}
</script>
<style lang="less" scoped>
@import url('@/assets/css/theme.less');
.topMenu{
    width: 100%;
    height: 100%;
    .topMenuStyle{
        width: 100%;
        height: 100%;
        overflow: hidden;
        .menuItem{
            float: left;
            text-align: center;
            padding: 0 30px;
            color: @text-base-color;
            font-size: @font-size-middle;
            cursor: pointer;
            label{
                cursor: pointer;
                display: block;
            }
            &.active{
                color: @color-active;
                font-weight: 600;
                position: relative;
                &::before{
                    content:'';
                    position: absolute;
                    width: 20px;
                    height: 3px;
                    border-radius: 4px;
                    background: @color-active;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
    }
}
</style>