import Menu from '@/components/menu/index.vue'
import Logo from '@/components/logo/index.vue'
import Account from '@/components/account/index.vue'
import MenuTop from '@/components/menu/topMenu.vue'
import SystemHeader from '@/components/systemHeader/index.vue'
import Uploader from '@/components/upload/Upload.vue'
import Confirm from '@/components/confirm/index.vue'
import NHeader from '@/views/main/notice/components/header.vue';
import ImageCropper from '@/components/cropper/cropper.vue'
// 需要复用多次的
export const components = {
    install(app){
        app.component('Logo',Logo)
        app.component('Menu',Menu)
        app.component('Account',Account)
        app.component('MenuTop',MenuTop),
        app.component('SystemHeader',SystemHeader)
        app.component('Uploader',Uploader)
        app.component('Confirm',Confirm)
        app.component('NHeader',NHeader)
        app.component('ImageCropper',ImageCropper)
    }
}